import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, signOut, updateProfile } from 'firebase/auth';
import { get, getDatabase, ref, set } from 'firebase/database';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../firebase';
import { userAuth } from '../firebase';

const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState({});
    const [loading, setLoading] = useState(true);
    const [role, setRole] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (JSON.stringify(user) !== JSON.stringify(currentUser)) {
                setCurrentUser(user);
                setLoading(false);

                try {
                    if (user) {
                        const db = getDatabase();
                        const profileRef = ref(db, `profiles/${user.uid}`);
                        const snapshot = await get(profileRef);

                        if (snapshot.exists()) {
                            const userData = snapshot.val();
                            console.log(userData);
                            const { role } = userData;
                            setRole(role);
                            navigate(`/${role}`);
                        }
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            }
        });

        return () => unsubscribe();
    }, [currentUser, navigate]); // Updated dependency array

    async function Signup(email, password, username) {
        try {
            const auth = getAuth();
            await createUserWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;
            await updateProfile(user, { displayName: username });
            setCurrentUser({ ...user });

            const database = getDatabase();
            const userRef = ref(database, `profiles/${user.uid}`);

            await set(userRef, {
                email: user.email,
                username: username,
                role: 'user'
            });

            setLoading(false);
        } catch (error) {
            console.error("Signup error:", error.message);
            setLoading(false);
        }
    }

    async function Login(email, password) {
        try {
            const auth = getAuth();
            await signInWithEmailAndPassword(auth, email, password);

            const user = auth.currentUser;

            const db = getDatabase();
            const profileRef = ref(db, `profiles/${user.uid}`);
            const snapshot = await get(profileRef);

            if (snapshot.exists()) {
                const userData = snapshot.val();
                console.log(userData);
                const { role } = userData;
                setRole(role);

                if (role === 'user') {
                    console.log("Login Normal user");
                    navigate('/user');
                } else if (role === 'admin') {
                    navigate('/admin');
                } else {
                    console.log('Undefined role or scenario');
                }
            } else {
                console.log('User profile does not exist');
            }

            setLoading(false);
        } catch (error) {
            console.error("Login error:", error.message);
            setLoading(false);
        }
    }

    function Logout() {
        const auth = getAuth();
        signOut(auth).then(() => {
            setCurrentUser(null);
            setRole(null);
        }).catch((error) => {
            console.error("Logout error:", error);
        });
    }

    async function handleSocialLogin(provider) {
        try {
            const result = await signInWithPopup(userAuth, provider);
            const user = result.user;

            // Check if the user already exists in the database
            const db = getDatabase();
            const profileRef = ref(db, `profiles/${user.uid}`);
            const snapshot = await get(profileRef);

            if (!snapshot.exists()) {
                // Create a user profile in Realtime Database for first-time social login
                await set(profileRef, {
                    email: user.email,
                    username: user.displayName,
                    role: 'user'
                });
            }

            // After successful login, fetch user data including role
            const userDataSnapshot = await get(profileRef);
            if (userDataSnapshot.exists()) {
                const userData = userDataSnapshot.val();
                setRole(userData.role);

                if (userData.role === 'user') {
                    console.log("Social Normal user");
                    navigate('/user');
                } else if (userData.role === 'admin') {
                    navigate('/admin');
                }
            }
        } catch (error) {
            console.error(`${provider.providerId} Sign-In Error:`, error);
        }
    }

    const value = {
        currentUser,
        Signup,
        Login,
        Logout,
        loading,
        handleSocialLogin,
        role
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

