import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState(null);

  const handleReset = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      {resetSent ? (
        <>
          <p className="text-green-600 text-lg mb-4">
            Password reset email sent. Check your inbox!
          </p>
          <Link
            to="/login"
            className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg text-center transition duration-300"
          >
            Back
          </Link>
        </>
      ) : (
        <form onSubmit={handleReset} className="flex flex-col items-center">
          <div className="mb-4 w-full max-w-md relative">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              className="w-full border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:ring focus:ring-indigo-200"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <AiOutlineMail className="h-6 w-6 text-gray-400 absolute top-1/2 transform -translate-y-1/2 right-3" />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white py-2 px-6 rounded-md transition duration-300 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
          >
            Reset Password
          </button>
          {error && (
            <p className="text-red-600 text-sm mt-2">{`Error: ${error}`}</p>
          )}
        </form>
      )}
    </div>
  );
}

export default ForgotPassword;
