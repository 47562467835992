import React, { useState, useRef, useEffect, useCallback } from 'react';
import './UserDashboard.css';
import { useAuth } from "../context/AuthContext";
import { getStorage, ref, uploadBytesResumable, listAll, getDownloadURL, deleteObject, uploadBytes, getMetadata } from "firebase/storage";
import { getFirestore, collection, addDoc, onSnapshot, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";

//component imports

import EnvironmentalTracker from '../components/EnvironmentalTracker';
import UserDropdown from '../components/UserDropdown';

import { Link } from "react-router-dom";

function AdminDashboard() {
  const { Logout, currentUser } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');    
  const [filter, setFilter] = useState('all'); // Assuming 'all' is the default filter
  const [showDeleteButtons, setShowDeleteButtons] = useState(false);
  const [chatPrompt, setChatPrompt] = useState('');
  const [chatResponse, setChatResponse] = useState('');
  const db = getFirestore();
  const [chatHistory, setChatHistory] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);

  //env tracker
  // Example user data (replace with actual data retrieval logic)
  const userData = {
    documentsCount: 50 // Example value
  };

  //transcript display

  const [selectedTranscriptFile, setSelectedTranscriptFile] = useState(null);

const showTranscript = (fileName) => {
  setUploadedFiles(currentFiles => currentFiles.map(file => {
    if (file.name === fileName) {
      // Extract just the file name without path for comparison
      const extractedText = extractedTexts.find(text => {
        const extractedFileName = text.file.split('/').pop(); // Adjust this line based on your file path structure
        return extractedFileName === fileName;
      });
      return {
        ...file,
        transcript: extractedText ? extractedText.text : "No transcript available.",
        showTranscript: !file.showTranscript
      };
    }
    return file;
  }));
};

  // New state for extracted texts
  const [extractedTexts, setExtractedTexts] = useState([]);
  const [showExtractedTexts, setShowExtractedTexts] = useState(false);

  // Function to fetch extracted texts
const fetchExtractedTexts = useCallback(async () => {
  const textsRef = collection(db, "extractedText");
  const querySnapshot = await getDocs(textsRef);
  const texts = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  console.log(texts); // Log to see if texts are being fetched
  setExtractedTexts(texts);
}, [db]);

  useEffect(() => {
    fetchExtractedTexts();
  }, [fetchExtractedTexts]);

  // Function to render the extracted text display
const ExtractedTextDisplay = ({ texts, onClose }) => {
  return (
    <div className="section-container extracted-texts-section">
      <div className="uploaded-files-header">
        <h3 className="uploaded-files-title">Extracted Texts</h3>
        <button onClick={onClose}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="files-in-category">
        {texts.map((text, index) => (
          <div key={index} className="file-item">
            <p>{text.text || "No text extracted."}</p>
          </div>
        ))}
        {texts.length === 0 && <p>No texts extracted.</p>}
      </div>
    </div>
  );
};
  
    // New state for category and camera capture
  const [selectedCategory, setSelectedCategory] = useState('Bills');
  const [cameraCapture, setCameraCapture] = useState(null);
  
    //categories
  const categories = ["Bills", "Expense Receipt", "Invoice", "Client File", /* other categories */ ];


    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Add toggleSidebar function
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleCameraCaptureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCameraCapture(file);
      setSelectedFile(file); // Set this file as the selected file for upload
    }
  };
  
  //const newMessageRef = collection(db, 'users', currentUser.uid, 'messages');

const fetchUploadedFiles = useCallback(async () => {
  if (currentUser && currentUser.uid) {
    const storage = getStorage();
    const userStorageRef = ref(storage, `uploads/${currentUser.uid}`);
    try {
      const filesList = await listAll(userStorageRef);
      const fileInfos = await Promise.all(filesList.items.map(async (fileRef) => {
        const url = await getDownloadURL(fileRef);
        const metadata = await getMetadata(fileRef);
    return {
      url: url,
      name: fileRef.name,
      category: metadata.customMetadata?.category || 'Unknown',
      showTranscript: false, // Add this line
      transcript: '' // Add this line
    };
  }));
  setUploadedFiles(fileInfos);
    } catch (error) {
      console.error('Error fetching uploaded files', error);
    }
  }
}, [currentUser]);

useEffect(() => {
  console.log("Current User:", currentUser);

  // Ensuring currentUser exists and has uid
  if (currentUser && currentUser.uid) {
  //  console.log("Current User UID:", currentUser.uid);
    fetchUploadedFiles();
  } else {
    console.log("No user logged in or currentUser is not set properly.");
  }
}, [currentUser, fetchUploadedFiles]);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

const startEditing = (fileName) => {
  setUploadedFiles(currentFiles => {
    const updatedFiles = currentFiles.map(file => file.name === fileName ? { ...file, isEditing: true } : file);
    console.log("Updated Files in startEditing:", updatedFiles);
    return updatedFiles;
  });
};

const handleNameChange = (event, fileName) => {
  setUploadedFiles(currentFiles =>
    currentFiles.map(file => file.name === fileName ? { ...file, newName: event.target.value } : file)
  );
};

const saveNewName = async (file) => {
  try {
    const storage = getStorage();
    const oldRef = ref(storage, `uploads/${currentUser.uid}/${file.name}`);
    const newRef = ref(storage, `uploads/${currentUser.uid}/${file.newName}`);

    // Step 1: Download the file
    const url = await getDownloadURL(oldRef);
    const response = await fetch(url);
    const blob = await response.blob();

    // Step 2: Upload the file with the new name
    await uploadBytes(newRef, blob);

    // Step 3: Delete the old file
    await deleteObject(oldRef);

    // Update local state
    setUploadedFiles(currentFiles =>
      currentFiles.map(f => f.name === file.name ? { ...f, name: file.newName, isEditing: false } : f)
    );
  } catch (error) {
    console.error('Error saving the new file name', error);
    // Optionally, update state to show an error message
  }
};

    // Function to handle changes to the filter dropdown
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleUpload = () => {
    setShowUploadModal(true);
  };

  // Add a function to handle search input changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Add a function to filter uploaded files based on the search term and filter
  const filteredFiles = uploadedFiles.filter(file => {
    return file.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filter === 'all' || file.type === filter); // Assume 'type' is a property of file
  });
  
const performFileUpload = () => {
  if (!currentUser) {
    setUploadError("You must be logged in to upload files.");
    return;
  }
  if (!selectedFile) {
    setUploadError("No file selected!");
    return;
  }

  setUploadError('');
  const storage = getStorage();
  const storageRef = ref(storage, `uploads/${currentUser.uid}/${selectedFile.name}`);

  // Include category as metadata
  const metadata = {
    contentType: selectedFile.type,
    customMetadata: {
      'category': selectedCategory
    }
  };

  const uploadTask = uploadBytesResumable(storageRef, selectedFile, metadata);

  uploadTask.on('state_changed',
    (snapshot) => {
      const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(prog);
    },
    (error) => {
      setUploadError(error.message);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
        setShowUploadModal(false);
        setProgress(0);
        setSelectedFile(null);
        fetchUploadedFiles();
      });
    }
  );
};

const groupFilesByCategory = () => {
  const filteredFiles = uploadedFiles.filter(file => {
    const matchesSearchTerm = file.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filter === 'all' || file.category === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const grouped = {};
  filteredFiles.forEach(file => {
    const category = file.category || 'Other'; // Fallback to 'Other' if no category
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push(file);
  });
  return grouped;
};

const renderUploadedFilesByCategory = () => {
  const groupedFiles = groupFilesByCategory();
  return Object.entries(groupedFiles).length > 0 ? (
    Object.entries(groupedFiles).map(([category, files]) => (
      <div key={category} className="category-section">
        <h3 className="category-title">{category}</h3>
        <div className="files-in-category">
          {files.map((file, index) => (
            <div key={index}>
              <div className="file-item">
                <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                {showDeleteButtons && (
                  <>
                    <button onClick={() => startEditing(file.name)}>Edit</button>
                    <button onClick={() => confirmDelete(file.name)}>Delete</button>
                    <button onClick={() => showTranscript(file.name)}>Transcript</button>
                  </>
                )}
              </div>
              {file.showTranscript && (
                <div className="file-transcript">
                  <p>{file.transcript}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    ))
  ) : (
    <p>No files in category: {filter}</p>
  );
};

const renderUploadedFiles = () => {
  // Filter files based on search term and filter selection
  const filteredFiles = uploadedFiles.filter(file => {
    const matchesSearchTerm = file.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filter === 'all' || file.type === filter; // assuming 'type' is a property of file
    return matchesSearchTerm && matchesFilter;
  });

  // Return JSX for rendering the filtered list of files
  return filteredFiles.length > 0 ? (
    filteredFiles.map((file, index) => (
      <div key={index}>
        <div className="file-item-container">
          <div className="file-item">
            {file.isEditing ? (
              <>
                <input
                  type="text"
                  value={file.newName}
                  onChange={(e) => handleNameChange(e, file.name)}
                />
                <button onClick={() => saveNewName(file)}>Save</button>
              </>
            ) : (
              <>
                <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                {showDeleteButtons && (
                  <>
                    <button onClick={() => startEditing(file.name)}>Edit</button>
                    <button onClick={() => confirmDelete(file.name)}>Delete</button>
                    <button onClick={() => showTranscript(file.name)}>Transcript</button>
                  </>
                )}
              </>
            )}
          </div>
          {file.showTranscript && (
            <div className="file-transcript">
              <p>{file.transcript}</p>
            </div>
          )}
        </div>
      </div>
    ))
  ) : (
    <p>No files found.</p>
  );
};

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

//file deltion code  
const confirmDelete = (fileName) => {
  if (window.confirm(`Are you sure you want to delete ${fileName}?`)) {
    deleteFile(fileName);
  }
};

const deleteFile = async (fileName) => {
  try {
    const storage = getStorage();
    const fileRef = ref(storage, `uploads/${currentUser.uid}/${fileName}`);

    // Delete the file
    await deleteObject(fileRef);

    // Update state to remove the file from the list
    setUploadedFiles(uploadedFiles.filter(file => file.name !== fileName));
  } catch (error) {
    console.error('Error deleting the file', error);
    // Optionally, update state to show an error message
  }
};

  const loadMoreMessages = () => {
  if (!lastVisible) return;

  const messagesRef = collection(db, 'users', currentUser.uid, 'messages');
  const nextQuery = query(messagesRef, orderBy('timestamp', 'desc'), startAfter(lastVisible), limit(5));

  getDocs(nextQuery).then((querySnapshot) => {
    const newMessages = [];
    let newLastVisibleDoc = null;

    querySnapshot.docs.forEach(doc => {
      newMessages.unshift({ id: doc.id, ...doc.data() });
      newLastVisibleDoc = doc;
    });

    setChatHistory(prevMessages => [...prevMessages, ...newMessages]);
    setLastVisible(newLastVisibleDoc);
  });
};

const sendMessage = async (messageText) => {
  if (!currentUser) return;

  const newMessage = {
    sender: currentUser.uid,
    prompt: messageText,
    response: null, // Initially null, to be filled by the AI
    timestamp: new Date()
  };

  await addDoc(collection(db, 'users', currentUser.uid, 'messages'), newMessage);
  setChatPrompt(''); // Reset the chat input field
};

useEffect(() => {
  if (currentUser && currentUser.uid) {
    const messagesRef = collection(db, 'users', currentUser.uid, 'messages');
    const q = query(messagesRef, orderBy('timestamp', 'desc'), limit(5));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      let lastVisibleDoc = null;

      querySnapshot.docs.forEach(doc => {
        messages.unshift({ id: doc.id, ...doc.data() });
        lastVisibleDoc = doc;
      });

      setChatHistory(messages);
      setLastVisible(lastVisibleDoc);
    });

    return () => unsubscribe(); // Clean up on unmount
  }
}, [currentUser]);

const sendChatPrompt = async () => {
  if (!currentUser || !chatPrompt) return;

  try {
    // Step 1: Add the new chat prompt to Firestore
    const docRef = await addDoc(collection(db, 'users', currentUser.uid, 'messages'), {
      prompt: chatPrompt,
      response: '', // Initially empty, to be filled by the AI
      timestamp: new Date() // Add a timestamp
    });
    setChatPrompt('');

    // Step 2: Listen for updates to this document (i.e., AI response)
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists() && doc.data().response) {
        const updatedMessage = doc.data();

        // Update the chat history with the new message
        setChatHistory(ch => [...ch, { sender: 'ai', text: updatedMessage.response, timestamp: new Date(updatedMessage.timestamp.seconds * 1000) }]);
      }
    });

    // Unsubscribe from the listener when appropriate (e.g., component unmounts or after response is received)
    return () => unsubscribe();
  } catch (error) {
    console.error("Error sending chat prompt: ", error);
  }
};

return (
  <div className="HoldingCell">
    {/* Main Dashboard Content */}
    <div className="main-content">
      {/* Uploaded Files Section */}
      <div className="uploaded-files-section">
        <div className="uploaded-files-header">
          <h3 className="uploaded-files-title">Uploaded Files</h3>
          <button onClick={() => setShowDeleteButtons(!showDeleteButtons)}>
            <span className="material-icons">settings</span>
          </button>
        </div>
        <div className="content-header">
          <input
            type="text"
            placeholder="Search files..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-bar"
          />
          <select value={filter} onChange={handleFilterChange} className="filter-dropdown">
            <option value="all">All</option>
            <option value="Bills">Bills</option>
            <option value="Expense Receipt">Expense Receipt</option>
            <option value="Invoice">Invoice</option>
            <option value="Client File">Client File</option>
          </select>
        </div>
        {showExtractedTexts ? (
          <ExtractedTextDisplay
            texts={extractedTexts}
            onClose={() => setShowExtractedTexts(false)}
          />
        ) : (
          renderUploadedFilesByCategory()
        )}
      </div>

      {/* Chat Section */}
      <div className="chat-section">
        <h3 className="uploaded-files-title">MoneyBooks Ai</h3>
        <div className="chat-interface">
          <div className="input-group">
            <input 
              type="text" 
              value={chatPrompt} 
              onChange={(e) => setChatPrompt(e.target.value)} 
              placeholder="Ask MoneyBooks anything..." 
            />
            <button onClick={() => sendMessage(chatPrompt)}>Send</button>
            <button onClick={loadMoreMessages}>Load More</button>
          </div>
        </div>
        <div className="chat-history">
          {chatHistory.map((msg, index) => (
            <div key={index} className="message-group">
              <div className="message question">
                <span className="message-text">Q: {msg.prompt}</span>
                <span className="message-timestamp">{new Date(msg.timestamp.seconds * 1000).toLocaleTimeString()}</span>
              </div>
              {msg.response && (
                <div className="message answer">
                  <span className="message-text">A: {msg.response}</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>

    {/* Hidden file input for triggering the file selection */}
    <input
      type="file"
      ref={fileInputRef}
      onChange={handleFileChange}
      style={{ display: 'none' }}
    />

{/* Upload Modal */}
{showUploadModal && (
  <div className="uploadModalOverlay">
    <div className="uploadModal">
      <div className="modalHeader">
        <h3>Upload File</h3>
        <button onClick={() => setShowUploadModal(false)}>X</button>
      </div>
      <div className="modalBody"
           onDragEnter={handleDragEnter}
           onDragLeave={handleDragLeave}
           onDragOver={handleDragOver}
           onDrop={handleDrop}>
        
        {/* Category Selection */}
        <select value={selectedCategory} onChange={handleCategoryChange}>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>

        {/* Camera Capture */}
        <input type="file" accept="image/*" capture="camera" onChange={handleCameraCaptureChange} />

        {/* Drop Area */}
        <div className={`dropArea ${isDragOver ? 'dragOver' : ''}`}
             onClick={() => fileInputRef.current && fileInputRef.current.click()}>
          {isDragOver ? "Drop the file here" : "Drag and drop files here or click to select"}
        </div>

        {uploadError && <div className="error">{uploadError}</div>}
        {selectedFile && <div className="file-name">{selectedFile.name}</div>}
        <div className="progressBarContainer">
          <div className="progressBar" style={{ width: `${progress}%` }}></div>
        </div>
        <button onClick={performFileUpload} disabled={!selectedFile || progress > 0}>Confirm Upload</button>
        <button onClick={() => setShowUploadModal(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}

      {/* Rest of your dashboard layout */}
    </div>
  );
}

export default AdminDashboard;
