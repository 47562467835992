import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAuth } from "../context/AuthContext";
import { getStorage, ref, uploadBytesResumable, listAll, getDownloadURL, deleteObject, uploadBytes, getMetadata } from "firebase/storage";
import { getFirestore, collection, addDoc, onSnapshot, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";

function ChatInterface() {
  const { Logout, currentUser } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');    
  const [filter, setFilter] = useState('all'); // Assuming 'all' is the default filter
  const [showDeleteButtons, setShowDeleteButtons] = useState(false);
  const [chatPrompt, setChatPrompt] = useState('');
  const [chatResponse, setChatResponse] = useState('');
  const db = getFirestore();
  const [chatHistory, setChatHistory] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);

const loadMoreMessages = () => {
  if (!lastVisible) return;

  const messagesRef = collection(db, 'users', currentUser.uid, 'messages');
  const nextQuery = query(messagesRef, orderBy('timestamp', 'desc'), startAfter(lastVisible), limit(5));

  getDocs(nextQuery).then((querySnapshot) => {
    const newMessages = [];
    let newLastVisibleDoc = null;

    querySnapshot.docs.forEach(doc => {
      newMessages.unshift({ id: doc.id, ...doc.data() });
      newLastVisibleDoc = doc;
    });

    setChatHistory(prevMessages => [...prevMessages, ...newMessages]);
    setLastVisible(newLastVisibleDoc);
  });
};

const sendMessage = async (messageText) => {
  if (!currentUser) return;

  const newMessage = {
    sender: currentUser.uid,
    prompt: messageText,
    response: null, // Initially null, to be filled by the AI
    timestamp: new Date()
  };

  await addDoc(collection(db, 'users', currentUser.uid, 'messages'), newMessage);
  setChatPrompt(''); // Reset the chat input field
};

useEffect(() => {
  if (currentUser && currentUser.uid) {
    const messagesRef = collection(db, 'users', currentUser.uid, 'messages');
    const q = query(messagesRef, orderBy('timestamp', 'desc'), limit(5));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      let lastVisibleDoc = null;

      querySnapshot.docs.forEach(doc => {
        messages.unshift({ id: doc.id, ...doc.data() });
        lastVisibleDoc = doc;
      });

      setChatHistory(messages);
      setLastVisible(lastVisibleDoc);
    });

    return () => unsubscribe(); // Clean up on unmount
  }
}, [currentUser]);

const sendChatPrompt = async () => {
  if (!currentUser || !chatPrompt) return;

  try {
    // Step 1: Add the new chat prompt to Firestore
    const docRef = await addDoc(collection(db, 'users', currentUser.uid, 'messages'), {
      prompt: chatPrompt,
      response: '', // Initially empty, to be filled by the AI
      timestamp: new Date() // Add a timestamp
    });
    setChatPrompt('');

    // Step 2: Listen for updates to this document (i.e., AI response)
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists() && doc.data().response) {
        const updatedMessage = doc.data();

        // Update the chat history with the new message
        setChatHistory(ch => [...ch, { sender: 'ai', text: updatedMessage.response, timestamp: new Date(updatedMessage.timestamp.seconds * 1000) }]);
      }
    });

    // Unsubscribe from the listener when appropriate (e.g., component unmounts or after response is received)
    return () => unsubscribe();
  } catch (error) {
    console.error("Error sending chat prompt: ", error);
  }
};
  
return (
  // Chat Section
  <div className="section-container">
    <h3 className="uploaded-files-title">MoneyBooks ChatBot</h3>
    <div className="chat-interface">
      <div className="input-group">
        <input
          type="text"
          value={chatPrompt}
          onChange={(e) => setChatPrompt(e.target.value)}
          placeholder="Ask MoneyBooks anything..."
        />
        <button onClick={() => sendMessage(chatPrompt)}>Send</button>
        <button onClick={loadMoreMessages}>Load More</button>
      </div>
    </div>
    <div className="chat-history">
      {chatHistory.map((msg, index) => (
        <div key={index} className="message-group">
          <div className="message question">
            <span className="message-text">Q: {msg.prompt}</span>
            <span className="message-timestamp">{new Date(msg.timestamp.seconds * 1000).toLocaleTimeString()}</span>
          </div>
          {msg.response && (
            <div className="message answer">
              <span className="message-text">A: {msg.response}</span>
            </div>
          )}
        </div>
      ))}
    </div>
  </div>
);

}
export default ChatInterface;
