import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';

import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

import { useNavigate } from 'react-router-dom';

import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import GroupsIcon from '@mui/icons-material/Groups';
import RecyclingIcon from '@mui/icons-material/Recycling';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';

import { useAuth } from "../context/AuthContext";
import { getStorage, ref, uploadBytesResumable, listAll, getDownloadURL, deleteObject, uploadBytes, getMetadata } from "firebase/storage";
import { getFirestore, collection, addDoc, onSnapshot, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";

import UploadModal from '../components/UploadModal';

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

const handleLinkClick = (e) => {
  console.log(`Attempting to navigate to ${to} for user ${currentUser ? currentUser.email : 'No user'}`);

  if (!currentUser) {
    console.log("Unauthorized access attempt to ", to);
    e.preventDefault(); // Prevent default link behavior
    alert('You must be logged in to access this page.'); // Show a message to the user
    return;
  }

  setSelected(title);
  console.log(`Navigation to ${to} allowed for user ${currentUser.email}`);
  navigate(to); // Programmatically navigate to the route
};

  return (
    <MenuItem
      active={selected === title}
      onClick={handleLinkClick} // Use the custom click handler
      icon={icon}
      style={{
        color: colors.grey[100],
      }}
    >
      {/* Wrap Link with div to handle click event */}
      <div style={{ textDecoration: 'none', color: 'inherit' }}>
        <Typography>{title}</Typography>
      </div>
    </MenuItem>
  );
};


  const Sidebar = ({ currentUser, Logout, showUploadModal, setShowUploadModal, onUploadClick, isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
 // const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");


    
        //categories
  const categories = ["Bills", "Expense Receipt", "Invoice", "Client File", /* other categories */ ];

    const handleUploadClick = () => {
    setShowUploadModal(true); // This will set showUploadModal to true and display the modal
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        overflowY: 'auto',
        zIndex: 1000,
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#fdd835 !important",
        },
        "& .pro-menu-item.active": {
          color: "#fdd835 !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: "#fdd835 !important",
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]} style={{ fontSize: '1.5rem' }}>
                  MoneyBooks
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`../MoneyBooks_Logo.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0", fontSize: '1.25rem' }}
                  
                >
                  {currentUser?.displayName || 'Username'}
                </Typography>
                  <Typography
    variant="body2" // Smaller variant for the email
    color={colors.greenAccent[100]}
    sx={{ fontSize: '1.25rem' }} // Custom font size
  >
                  {currentUser?.email}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px", fontSize: '0.75rem' }}
            >
              Data
            </Typography>
            <Item
              title="Contacts"
              to="/contacts"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            
<Item title="Upload File" icon={<UploadFileIcon />}>
  <button onClick={() => setShowUploadModal(true)}>Open Upload Modal</button>
</Item>
            
            <Item
              title="Files"
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px", fontSize: '0.75rem' }}
            >
              Pages
            </Typography>
            <Item
              title="MoneyBooks Ai"
              to="/Form"
              icon={<ChatOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
<Item
  title="Calendar"
  to="/Calendar"
  icon={<CalendarTodayOutlinedIcon />}
  selected={selected}
  setSelected={setSelected}
/>
            <Item
              title="FAQ"
              to="/FAQ"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
              <Item
              title="Eco Impact"
              to="/team"
              icon={<RecyclingIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px", fontSize: '0.75rem' }}
            >
              Charts
            </Typography>
            <Item
              title="Budget"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Expenses"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Invoices"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
