//import React, { useState } from 'react';
//import Sidebar from './Sidebar';
//import Topbar from './Topbar';
//import { useAuth } from "../context/AuthContext";
//import { Box } from "@mui/material";

//const MainLayout = ({ children }) => {
//  const { currentUser } = useAuth();
//  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

//  const toggleSidebar = () => {
//    setIsSidebarCollapsed(!isSidebarCollapsed);
//  };

//  const sidebarWidth = isSidebarCollapsed ? '80px' : '250px'; // Adjust these values as needed

//  return (
//    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
//      {currentUser && <Topbar />}
//      <Box sx={{ display: 'flex', width: '100%' }}>
//        {currentUser && <Sidebar currentUser={currentUser} isCollapsed={isSidebarCollapsed} onToggleSidebar={toggleSidebar} />}
//        <Box component="main" sx={{ flexGrow: 1, p: 3, marginLeft: sidebarWidth }}>
//          {children}
//        </Box>
//      </Box>
//    </Box>
//  );
//};

//import React, { useState } from 'react';
//import Sidebar from './Sidebar';
//import Topbar from './Topbar';
//import { useAuth } from "../context/AuthContext";
//import { Box } from "@mui/material";

//const MainLayout = ({ children }) => {
//  const { currentUser } = useAuth();
//  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

//  const collapsedWidth = '80px';
//  const expandedWidth = '250px';

//  return (
//    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
//      {currentUser && <Topbar />}
//      <Box sx={{ display: 'flex', width: '100%' }}>
//        {currentUser && (
//          <Sidebar
//            currentUser={currentUser}
//            isCollapsed={isSidebarCollapsed}
//            setIsCollapsed={setIsSidebarCollapsed}
//          />
//        )}
//        <Box component="main" sx={{ flexGrow: 1, p: 3, marginLeft: isSidebarCollapsed ? collapsedWidth : expandedWidth }}>
//          {children}
//        </Box>
//      </Box>
//    </Box>
//  );
//};

//export default MainLayout;

import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { useAuth } from "../context/AuthContext";
import { Box } from "@mui/material";
import MainLayoutimage from '../assets/MainLayoutimage.png'; // Ensure this path is correct

const MainLayout = ({ children }) => {
  const { currentUser } = useAuth();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const collapsedWidth = '80px';
  const expandedWidth = '250px';

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
      {currentUser && <Topbar />}
      <Box sx={{ display: 'flex', width: '100%' }}>
        {currentUser && (
          <Sidebar
            currentUser={currentUser}
            isCollapsed={isSidebarCollapsed}
            setIsCollapsed={setIsSidebarCollapsed}
          />
        )}
        <Box component="main" sx={{ 
          flexGrow: 1, 
          p: 3,
          marginTop: '50px',
          marginBottom: '20px', 
          marginLeft: isSidebarCollapsed ? collapsedWidth : expandedWidth, 
          width: `calc(100% - ${isSidebarCollapsed ? collapsedWidth : expandedWidth})`,
          background: `url(${MainLayoutimage}) no-repeat center center`, // Set the login image as background
          backgroundSize: 'cover' // Ensure the background covers the entire element
        }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;


//import React from 'react';
//import Sidebar from './Sidebar';
//import Topbar from './Topbar';
//import { useAuth } from "../context/AuthContext";
//import { Box } from "@mui/material";

//const MainLayout = ({ children }) => {
//  const { currentUser } = useAuth();

//  return (
//    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
 //     {currentUser && <Topbar />}
//      <Box sx={{ display: 'flex', width: '100%' }}>
//        {currentUser && <Sidebar currentUser={currentUser} />}
//        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
//          {children}
//        </Box>
//      </Box>
//    </Box>
//  );
//};

//export default MainLayout;


//import React from 'react';
//import Sidebar from './Sidebar';
//import Topbar from './Topbar';
//import { useAuth } from "../context/AuthContext";
//import { Box } from "@mui/material";

//const MainLayout = ({ children }) => {
//  const { currentUser } = useAuth();
//  const sidebarWidth = '250px'; // Adjust this based on your sidebar's width

//  return (
//    <Box sx={{ display: 'flex', height: '100vh', width: '100%' }}>
//      {currentUser && <Topbar />}
//      <Box sx={{ display: 'flex', width: '100%' }}>
//        {currentUser && <Sidebar currentUser={currentUser} />}
//        <Box component="main" sx={{ flexGrow: 1, p: 3, marginLeft: sidebarWidth }}>
//          {children}
//        </Box>
//      </Box>
//    </Box>
//  );
//};

//export default MainLayout;
