import { Box } from "@mui/material";
import Header from "../components/Header";
import PieChart from "../components/PieChart";
import '../pages/UserDashboard.css';

const Pie = () => {
  return (
    <Box className="HoldingCell" m="20px">
      <Header title="Budget" subtitle="2023" />
      <Box height="75vh">
        <PieChart />
      </Box>
    </Box>
  );
};

export default Pie;
