import { Box, IconButton, useTheme, InputBase } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext } from "../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";

import { useAuth } from "../context/AuthContext";

const Topbar = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const { Logout } = useAuth();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
      backgroundColor={theme.palette.background.paper}
      height="70px"
      padding-bottom="140px"
      box-shadow="none"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        maxWidth="1280px"
        px={2}
      >
        <Box flex={1} display={{ xs: 'none', md: 'flex' }} />

        <Box
          display="flex"
          backgroundColor={theme.palette.grey[200]}
          borderRadius="3px"
          width={{ xs: '100%', sm: 'auto' }} // Full width on small screens, auto on larger
          maxWidth="600px"
        >
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search MoneyBooks" />
          <IconButton type="button" sx={{ p: '10px' }}>
            <SearchIcon />
          </IconButton>
        </Box>

        <Box display="flex" flex={1} justifyContent="flex-end">
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
          </IconButton>
          <IconButton><NotificationsOutlinedIcon /></IconButton>
          <IconButton><SettingsOutlinedIcon /></IconButton>
          <IconButton onClick={Logout}><PersonOutlinedIcon /></IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
