import React from "react";
import { Navigate, Route, Routes, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import AdminDashboard from "../pages/AdminDashboard";
import UserDashboard from "../pages/UserDashboard";
import ForgotPassword from "../pages/ForgotPassword";
import LoginPage from "../pages/LoginPage";
import RegistrationPage from "../pages/RegistrationPage";
import Form from "../components/Form"; 
import Calendar from "../components/calendar"; 
import FAQ from "../components/FAQ"; 
import HelloWorld from "../components/HelloWorld"; 
import Invoices from "../components/Invoices"; 
import Team from "../components/Team"; 
import Pie from "../components/Pie";
import Geography from "../components/Geography"; 
import Line from "../components/Line"; 
import Contacts from "../components/Contacts"; 

function MainRouter() {
  let { currentUser } = useAuth();

  return (
    <Routes>
      {currentUser ? (
        <>
          <Route path="/user" element={<UserDashboard />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/profile" element={<Form />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/form" element={<Form />} />
          <Route path="/team" element={<Team />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/pie" element={<Pie />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/line" element={<Line />} />
          <Route path="/geography" element={<Geography />} />
        </>
      ) : (
        <>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </>
      )}
      {/* Redirect user to the appropriate dashboard or login page */}
      <Route
        path="*"
        element={<Navigate replace to={currentUser ? "/user" : "/login"} />}
      /> 
    </Routes>
  );
}

export default MainRouter;
