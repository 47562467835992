import { Box, useTheme } from "@mui/material";
import GeographyChart from "../components/GeographyChart";
import Header from "../components/Header";
import { tokens } from "../theme";
import '../pages/UserDashboard.css';

const Geography = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box className="HoldingCell" m="20px">
      <Header title="Clients" subtitle="2023" />

      <Box
        height="75vh"
        border={`1px solid ${colors.grey[100]}`}
        borderRadius="4px"
      >
        <GeographyChart />
      </Box>
    </Box>
  );
};

export default Geography;
