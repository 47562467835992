import React from 'react';
import MainLayout from './components/MainLayout'; 
import MainRouter from './router/MainRouter';

function App() {
  return (
    <MainLayout>
      <MainRouter />
    </MainLayout>
  );
}

export default App;





