import { Box, useTheme } from "@mui/material";
import Header from "../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../theme";
import '../pages/UserDashboard.css';

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box className="HoldingCell" m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions - MoneyBooks Accounting" />
      
      {/* Question 1 */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How Can I Prepare for Tax Season?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Start by gathering all necessary financial documents, including income statements, expenses, and any relevant tax forms. It's also helpful to review last year's return for reference.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 2 */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What Accounting Services Do You Offer?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We offer a range of services including bookkeeping, tax preparation, payroll management, and financial consulting tailored to both individuals and businesses.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 3 */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            How Can I Access My Financial Records Online?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Clients can access their financial records through our secure online portal. Simply log in with your provided credentials to view and download your documents.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 4 */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What Is the Best Way to Contact My Accountant?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can contact your accountant via email, phone, or through our client portal. We strive to respond to all inquiries within one business day.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* Question 5 */}
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Are Consultations Included in Your Services?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, initial consultations are included. We discuss your financial situation and determine how our services can best meet your needs.
          </Typography>
        </AccordionDetails>
      </Accordion>

    </Box>
  );
};

export default FAQ;
