import React, { useState } from "react";
import { FaApple, FaEye, FaEyeSlash, FaGoogle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import RegistrationImage from "../assets/desktop.png";
import { useAuth } from "../context/AuthContext";
import { appleProvider, googleProvider } from "../firebase";

// Import the background image
import Registerimg from '../assets/Registerimg.png';

function RegistrationPage() {
  const { Signup, handleSocialLogin } = useAuth();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleLogin = () => {
    handleSocialLogin(googleProvider);
  };

  const handleAppleLogin = () => {
    handleSocialLogin(appleProvider);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreeToTerms) {
      alert('Please agree to the terms and conditions.');
      return;
    }
    try {
      await Signup(email, password, username);
      navigate("/user");
    } catch (error) {
      console.error("Registration error:", error.message);
    }
  };

  return (
<div style={{ 
   backgroundImage: `url(${Registerimg})`,
   backgroundSize: 'cover',
   backgroundPosition: 'center',
   minHeight: '100vh',
}}>
    <section className="min-h-screen flex items-center justify-center">
        <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
          <div className="md:block hidden w-1/2">
            <img
              className="rounded-2xl"
              src={RegistrationImage}
              alt="Registration"
            />
          </div>
          <div className="md:w-1/2 px-8 md:px-16">
            <h2 className="font-bold text-2xl text-[#002D74]">Register to MoneyBooks</h2>
            <p className="text-xs mt-4 text-[#002D74]">
              Create a new account to get started
            </p>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <input
                className="p-2 mt-8 rounded-xl border"
                type="text"
                name="username"
                placeholder="Name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                className="p-2 rounded-xl border"
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="relative">
                <input
                  className="p-2 rounded-xl border w-full"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span onClick={handlePassword} className="absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer">
                  {showPassword ? <FaEyeSlash className="text-gray-500" /> : <FaEye className="text-gray-500" />}
                </span>
              </div>
   <div className="p-2 mt-4 rounded-xl border flex items-center bg-white">
          <input
            type="checkbox"
            id="agree-terms"
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
            style={{ marginRight: '10px' }}
          />
          <label htmlFor="agree-terms" style={{ color: 'gray' }}> {/* Adjust this color as needed */}
            I agree to the <span style={{ color: '#002D74', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowTermsModal(true)}>T&C's</span>
          </label>
        </div>
              <button
                type="submit"
                className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300"
              >
                Register
              </button>
              {showTermsModal && (
                <div style={{
                  position: 'fixed', top: 0, left: 0, width: '100%', height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center',
                  justifyContent: 'center', zIndex: 1000
                }}>
                  <div style={{
                    background: 'white', padding: '40px', borderRadius: '10px',
                    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)', maxWidth: '500px', width: '90%'
                  }}>
                    <span style={{ float: 'right', fontSize: '1.2rem', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => setShowTermsModal(false)}>&times;</span>
                    <h2>Terms & Conditions</h2>
                    <p>Filler text for Terms and Conditions...</p>
                  </div>
                </div>
              )}
            </form>
            <div className="mt-6 grid grid-cols-3 items-center text-gray-400">
              <hr className="border-gray-400" />
              <p className="text-center text-sm">OR</p>
              <hr className="border-gray-400" />
            </div>
            <button
              onClick={handleGoogleLogin}
              className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]"
            >
              <FaGoogle className="mr-3" /> Register with Google
            </button>
            <button
              onClick={handleAppleLogin}
              className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]"
            >
              <FaApple className="mr-3" /> Register with Apple
            </button>
            <div className="mt-5 text-xs flex justify-between items-center text-[#002D74]">
              <p>Already have an account?</p>
              <Link
                to="/login"
                className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300"
              >
                Login
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default RegistrationPage;
