import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useAuth } from "../context/AuthContext";
import { getStorage, ref, uploadBytesResumable, listAll, getDownloadURL, getMetadata } from "firebase/storage";

function UploadModal({ showUploadModal, setShowUploadModal, selectedCategory, handleCategoryChange, categories, handleCameraCaptureChange }) {
  const { currentUser } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [isDragOver, setIsDragOver] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]); // Added state for uploaded files
  const fileInputRef = useRef(null);

  const onUploadClick = (event) => {
  event.preventDefault();
  console.log("Upload button clicked"); // Debugging line
  setShowUploadModal(true);
};

      //categories
 // const categories = ["Bills", "Expense Receipt", "Invoice", "Client File", /* other categories */ ];
  
  // Fetch uploaded files (moved outside of performFileUpload)
  const fetchUploadedFiles = useCallback(async () => {
    if (currentUser && currentUser.uid) {
      const storage = getStorage();
      const userStorageRef = ref(storage, `uploads/${currentUser.uid}`);
      try {
        const filesList = await listAll(userStorageRef);
        const fileInfos = await Promise.all(filesList.items.map(async (fileRef) => {
          const url = await getDownloadURL(fileRef);
          const metadata = await getMetadata(fileRef);
          return {
            url: url,
            name: fileRef.name,
            category: metadata.customMetadata?.category || 'Unknown',
            showTranscript: false,
            transcript: ''
          };
        }));
        setUploadedFiles(fileInfos);
      } catch (error) {
        console.error('Error fetching uploaded files', error);
      }
    }
  }, [currentUser]);

  // Effect for fetching files (moved outside of performFileUpload)
  useEffect(() => {
    console.log("Current User:", currentUser);
    if (currentUser && currentUser.uid) {
      fetchUploadedFiles();
    } else {
      console.log("No user logged in or currentUser is not set properly.");
    }
  }, [currentUser, fetchUploadedFiles]);

  const performFileUpload = () => {
    if (!currentUser) {
      setUploadError("You must be logged in to upload files.");
      return;
    }
    if (!selectedFile) {
      setUploadError("No file selected!!");
      return;
    }
    setUploadError('');
    const storage = getStorage();
    const storageRef = ref(storage, `uploads/${currentUser.uid}/${selectedFile.name}`);

    const metadata = {
      contentType: selectedFile.type,
      customMetadata: {
        'category': selectedCategory
      }
    };

    const uploadTask = uploadBytesResumable(storageRef, selectedFile, metadata);

    uploadTask.on('state_changed',
      (snapshot) => {
        const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(prog);
      },
      (error) => {
        setUploadError(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          setShowUploadModal(false);
          setProgress(0);
          setSelectedFile(null);
          fetchUploadedFiles();
        });
      }
    );
  };

    const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  if (!showUploadModal) {
    return null;
  }

  return (
    <div className="uploadModalOverlay">
      <div className="uploadModal">
        <div className="modalHeader">
          <h3>Upload File</h3>
          <button onClick={() => setShowUploadModal(false)}>X</button>
        </div>
        <div className="modalBody"
             onDragEnter={handleDragEnter}
             onDragLeave={handleDragLeave}
             onDragOver={handleDragOver}
             onDrop={handleDrop}>
          
          {/* Category Selection */}
          <select value={selectedCategory} onChange={handleCategoryChange}>
            {categories.map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>

          {/* Camera Capture */}
          <input type="file" accept="image/*" capture="camera" onChange={handleCameraCaptureChange} />

          {/* Drop Area */}
          <div className={`dropArea ${isDragOver ? 'dragOver' : ''}`}
               onClick={() => fileInputRef.current && fileInputRef.current.click()}>
            {isDragOver ? "Drop the file here" : "Drag and drop files here or click to select"}
          </div>

          {uploadError && <div className="error">{uploadError}</div>}
          {selectedFile && <div className="file-name">{selectedFile.name}</div>}
          <div className="progressBarContainer">
            <div className="progressBar" style={{ width: `${progress}%` }}></div>
          </div>
          <button onClick={performFileUpload} disabled={!selectedFile || progress > 0}>Confirm Upload</button>
          <button onClick={() => setShowUploadModal(false)}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default UploadModal;
