import { tokens } from "../theme";

export const mockDataTeam = [
  {
    id: 1,
    name: "Lewis Valentine",
    email: "lewis.valentine@moneybooks.co.uk",
    age: 40,
    phone: "020 7946 0958",
    access: "admin",
  },
  {
    id: 2,
    name: "Emma Thompson",
    email: "emma.thompson@moneybooks.co.uk",
    age: 35,
    phone: "020 7946 1234",
    access: "manager",
  },
  {
    id: 3,
    name: "Oliver Smith",
    email: "oliver.smith@moneybooks.co.uk",
    age: 30,
    phone: "020 7946 5678",
    access: "user",
  },
  {
    id: 4,
    name: "Sophia Johnson",
    email: "sophia.johnson@moneybooks.co.uk",
    age: 28,
    phone: "020 7946 9101",
    access: "user",
  },
];

export const mockDataContacts = [
  {
    id: 1,
    name: "Lewis Valentine",
    email: "lewis.valentine@moneybooks.co.uk",
    age: 40,
    phone: "020 7946 0018",
    address: "12 Baker Street, London, LDN 1AB",
    city: "London",
    zipCode: "LDN 1AB",
    registrarId: 100001,
  },
  {
    id: 2,
    name: "Luke Skywalker",
    email: "luke.skywalker@galacticmail.com",
    age: 35,
    phone: "020 7946 0028",
    address: "88 Jedi Lane, Coruscant, CRS 2BB",
    city: "Coruscant",
    zipCode: "CRS 2BB",
    registrarId: 100002,
  },
  {
    id: 3,
    name: "Leia Organa",
    email: "leia.organa@rebellion.org",
    age: 34,
    phone: "020 7946 0038",
    address: "47 Alderaan Way, New Alderaan, NAL 3CC",
    city: "New Alderaan",
    zipCode: "NAL 3CC",
    registrarId: 100003,
  },
  {
    id: 4,
    name: "Han Solo",
    email: "han.solo@falconpilots.com",
    age: 42,
    phone: "020 7946 0048",
    address: "32 Millennium Falcon Ave, Corellia, COR 4DD",
    city: "Corellia",
    zipCode: "COR 4DD",
    registrarId: 100004,
  },
  {
    id: 5,
    name: "Rey Skywalker",
    email: "rey.skywalker@newjedi.com",
    age: 25,
    phone: "020 7946 0058",
    address: "5 Resistance Base, D'Qar, DQR 5EE",
    city: "D'Qar",
    zipCode: "DQR 5EE",
    registrarId: 100005,
  },
  {
    id: 6,
    name: "Finn Stormtrooper",
    email: "finn.stormtrooper@resistance.org",
    age: 28,
    phone: "020 7946 0068",
    address: "77 Rebel Path, Takodana, TKD 6FF",
    city: "Takodana",
    zipCode: "TKD 6FF",
    registrarId: 100006,
  },
  {
    id: 7,
    name: "Poe Dameron",
    email: "poe.dameron@xwingace.com",
    age: 32,
    phone: "020 7946 0078",
    address: "99 Pilot's Way, Yavin 4, YVN 7GG",
    city: "Yavin 4",
    zipCode: "YVN 7GG",
    registrarId: 100007,
  },
  {
    id: 8,
    name: "Kylo Ren",
    email: "kylo.ren@firstorder.com",
    age: 30,
    phone: "020 7946 0088",
    address: "66 Dark Side Street, Starkiller Base, SKB 8HH",
    city: "Starkiller Base",
    zipCode: "SKB 8HH",
    registrarId: 100008,
  },
  {
    id: 9,
    name: "Mace Windu",
    email: "mace.windu@jedicouncil.org",
    age: 50,
    phone: "020 7946 0098",
    address: "22 Jedi Temple, Coruscant, CRS 9II",
    city: "Coruscant",
    zipCode: "CRS 9II",
    registrarId: 100009,
  },
  {
    id: 10,
    name: "Obi-Wan Kenobi",
    email: "obiwan.kenobi@oldrepublic.org",
    age: 57,
    phone: "020 7946 0108",
    address: "33 High Ground Road, Stewjon, STJ 10JJ",
    city: "Stewjon",
    zipCode: "STJ 10JJ",
    registrarId: 100010,
  },
  {
    id: 11,
    name: "Yoda Master",
    email: "yoda.master@jedicouncil.org",
    age: 900,
    phone: "020 7946 0118",
    address: "1 Jedi Temple, Dagobah, DGB 11KK",
    city: "Dagobah",
    zipCode: "DGB 11KK",
    registrarId: 100011,
  },
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Lewis Valentine",
    email: "lewis.valentine@moneybooks.co.uk",
    cost: "350.00",
    phone: "020 7946 0018",
    date: "03/12/2023",
  },
  {
    id: 2,
    name: "Darth Vader",
    email: "darth.vader@empire.gov",
    cost: "1500.00",
    phone: "020 7946 0028",
    date: "06/15/2023",
  },
  {
    id: 3,
    name: "Padme Amidala",
    email: "padme.amidala@naboo.gov",
    cost: "750.00",
    phone: "020 7946 0038",
    date: "05/02/2023",
  },
  {
    id: 4,
    name: "Lando Calrissian",
    email: "lando.calrissian@cloudcity.com",
    cost: "950.55",
    phone: "020 7946 0048",
    date: "03/21/2023",
  },
  {
    id: 5,
    name: "Qui-Gon Jinn",
    email: "qui-gon.jinn@jediorder.org",
    cost: "1200.24",
    phone: "020 7946 0058",
    date: "01/12/2023",
  },
  {
    id: 6,
    name: "Mace Windu",
    email: "mace.windu@jediorder.org",
    cost: "630.12",
    phone: "020 7946 0068",
    date: "11/02/2023",
  },
  {
    id: 7,
    name: "Boba Fett",
    email: "boba.fett@bountyhunters.com",
    cost: "1520.42",
    phone: "020 7946 0078",
    date: "02/11/2023",
  },
  {
    id: 8,
    name: "Mon Mothma",
    email: "mon.mothma@rebellion.org",
    cost: "2100.24",
    phone: "020 7946 0088",
    date: "05/02/2023",
  },
];

export const mockTransactions = [
  {
    txId: "MB01TX",
    user: "Lewis Valentine",
    date: "2023-09-01",
    cost: "250.00",
  },
  {
    txId: "MB02TX",
    user: "Obi-Wan Kenobi",
    date: "2023-04-01",
    cost: "450.00",
  },
  {
    txId: "MB03TX",
    user: "Han Solo",
    date: "2023-09-01",
    cost: "320.00",
  },
  {
    txId: "MB04TX",
    user: "Leia Organa",
    date: "2023-11-05",
    cost: "600.00",
  },
  {
    txId: "MB05TX",
    user: "Luke Skywalker",
    date: "2023-11-02",
    cost: "150.00",
  },
  {
    txId: "MB06TX",
    user: "Rey Skywalker",
    date: "2023-09-01",
    cost: "430.00",
  },
  {
    txId: "MB07TX",
    user: "Darth Vader",
    date: "2023-04-15",
    cost: "520.00",
  },
  {
    txId: "MB08TX",
    user: "Yoda",
    date: "2023-04-01",
    cost: "300.00",
  },
];

export const mockBarData = [
  {
    country: "AD",
    "Tax Returns": 137,
    "Tax ReturnsColor": "hsl(229, 70%, 50%)",
    "Financial Audits": 96,
    "Financial AuditsColor": "hsl(296, 70%, 50%)",
    "Expense Reports": 72,
    "Expense ReportsColor": "hsl(97, 70%, 50%)",
    "Payroll Processing": 140,
    "Payroll ProcessingColor": "hsl(340, 70%, 50%)",
  },
  {
    country: "AE",
    "Tax Returns": 55,
    "Tax ReturnsColor": "hsl(307, 70%, 50%)",
    "Financial Audits": 28,
    "Financial AuditsColor": "hsl(111, 70%, 50%)",
    "Expense Reports": 58,
    "Expense ReportsColor": "hsl(273, 70%, 50%)",
    "Payroll Processing": 29,
    "Payroll ProcessingColor": "hsl(275, 70%, 50%)",
  },
  {
    country: "AF",
    "Tax Returns": 109,
    "Tax ReturnsColor": "hsl(72, 70%, 50%)",
    "Financial Audits": 23,
    "Financial AuditsColor": "hsl(96, 70%, 50%)",
    "Expense Reports": 34,
    "Expense ReportsColor": "hsl(106, 70%, 50%)",
    "Payroll Processing": 152,
    "Payroll ProcessingColor": "hsl(256, 70%, 50%)",
  },
  {
    country: "AG",
    "Tax Returns": 133,
    "Tax ReturnsColor": "hsl(257, 70%, 50%)",
    "Financial Audits": 52,
    "Financial AuditsColor": "hsl(326, 70%, 50%)",
    "Expense Reports": 43,
    "Expense ReportsColor": "hsl(110, 70%, 50%)",
    "Payroll Processing": 83,
    "Payroll ProcessingColor": "hsl(9, 70%, 50%)",
  },
  {
    country: "AI",
    "Tax Returns": 81,
    "Tax ReturnsColor": "hsl(190, 70%, 50%)",
    "Financial Audits": 80,
    "Financial AuditsColor": "hsl(325, 70%, 50%)",
    "Expense Reports": 112,
    "Expense ReportsColor": "hsl(54, 70%, 50%)",
    "Payroll Processing": 35,
    "Payroll ProcessingColor": "hsl(285, 70%, 50%)",
  },
  {
    country: "AL",
    "Tax Returns": 66,
    "Tax ReturnsColor": "hsl(208, 70%, 50%)",
    "Financial Audits": 111,
    "Financial AuditsColor": "hsl(334, 70%, 50%)",
    "Expense Reports": 167,
    "Expense ReportsColor": "hsl(182, 70%, 50%)",
    "Payroll Processing": 18,
    "Payroll ProcessingColor": "hsl(76, 70%, 50%)",
  },
  {
    company: "Firm One",
    "Tax Returns": 80,
    "Tax ReturnsColor": "hsl(87, 70%, 50%)",
    "Financial Audits": 47,
    "Financial AuditsColor": "hsl(141, 70%, 50%)",
    "Expense Reports": 158,
    "Expense ReportsColor": "hsl(224, 70%, 50%)",
    "Payroll Processing": 49,
    "Payroll ProcessingColor": "hsl(274, 70%, 50%)",
  },
];


export const mockPieData = [
  {
    id: "TaxFiling",
    label: "Tax Filing",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "Auditing",
    label: "Auditing",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "PayrollManagement",
    label: "Payroll Management",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "ExpenseTracking",
    label: "Expense Tracking",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "FinancialConsulting",
    label: "Financial Consulting",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "QuarterlyEarnings",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "Q1",
        y: 101,
      },
      {
        x: "Q2",
        y: 75,
      },
      {
        x: "Q3",
        y: 36,
      },
      {
        x: "Q4",
        y: 216,
      },
    ],
  },
  {
    id: "ClientGrowth",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "Q1",
        y: 212,
      },
      {
        x: "Q2",
        y: 190,
      },
      {
        x: "Q3",
        y: 270,
      },
      {
        x: "Q4",
        y: 9,
      },
    ],
  },
  {
    id: "ExpenseTrends",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "Q1",
        y: 191,
      },
      {
        x: "Q2",
        y: 136,
      },
      {
        x: "Q3",
        y: 91,
      },
      {
        x: "Q4",
        y: 190,
      },
    ],
  },
];


export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];
