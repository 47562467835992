import React from 'react';

function EnvironmentalTracker({ userData }) {
  // Example calculations with mock data
  const paperSaved = calculatePaperSaved(userData.documentsCount || 0);
  const carbonFootprintReduction = calculateCarbonReduction(userData.documentsCount || 0);
  const energySaved = calculateEnergySaved(userData.documentsCount || 0); // New metric
  const waterSaved = calculateWaterSaved(userData.documentsCount || 0); // New metric

  // Inline CSS styles
  const styles = {
    container: {
      padding: '20px',
      backgroundColor: '#f5f5f5',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      maxWidth: '600px',
      margin: '20px auto'
    },
    header: {
      textAlign: 'center',
      color: '#333',
      marginBottom: '20px'
    },
    metric: {
      marginBottom: '15px',
      fontSize: '18px',
      backgroundColor: '#e8f5e9',
      padding: '10px',
      borderRadius: '4px'
    },
    title: {
      fontWeight: 'bold',
      marginRight: '10px'
    },
    value: {
      color: '#4CAF50',
      fontWeight: 'bold'
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Environmental Impact</h2>
      <div style={styles.metric}>
        <span style={styles.title}>Paper Saved:</span>
        <span style={styles.value}>{paperSaved} sheets</span>
      </div>
      <div style={styles.metric}>
        <span style={styles.title}>Carbon Footprint Reduction:</span>
        <span style={styles.value}>{carbonFootprintReduction.toFixed(2)} kg CO2</span>
      </div>
      <div style={styles.metric}>
        <span style={styles.title}>Energy Saved:</span>
        <span style={styles.value}>{energySaved.toFixed(2)} kWh</span>
      </div>
      <div style={styles.metric}>
        <span style={styles.title}>Water Saved:</span>
        <span style={styles.value}>{waterSaved.toFixed(2)} liters</span>
      </div>
      {/* Add more metrics as needed */}
    </div>
  );
}

export default EnvironmentalTracker;

// Example Utility functions with mock calculations
function calculatePaperSaved(documentsCount) {
  return documentsCount * 2; // 1 digital document saves 2 sheets of paper
}

function calculateCarbonReduction(documentsCount) {
  return documentsCount * 0.005; // 1 digital document reduces 0.005 kg CO2
}

function calculateEnergySaved(documentsCount) {
  return documentsCount * 0.02; // 1 digital document saves 0.02 kWh
}

function calculateWaterSaved(documentsCount) {
  return documentsCount * 1.5; // 1 digital document saves 1.5 liters of water
}
