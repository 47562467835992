import React, { useState, useRef, useEffect, useCallback } from 'react';
import './UserDashboard.css';
import { useAuth } from "../context/AuthContext";

import { useNavigate } from 'react-router-dom';

import { getStorage, ref, uploadBytesResumable, listAll, getDownloadURL, deleteObject, uploadBytes, getMetadata } from "firebase/storage";
import { getFirestore, collection, addDoc, onSnapshot, query, orderBy, limit, startAfter, getDocs } from "firebase/firestore";

import { Chart, registerables } from 'chart.js'; // Moved to top
import EnvironmentalTracker from '../components/EnvironmentalTracker';
import TaxBillTracker from '../components/TaxBillTracker';
import AlertsAndNotifications from '../components/AlertsAndNotifications';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import ChatInterface from '../components/ChatInterface';
import UploadModal from '../components/UploadModal';

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockTransactions } from "../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../components/Header";
import Line from "../components/Line";
import Geography from "../components/Geography";
import BarChart from "../components/BarChart";
import StatBox from "../components/StatBox";
import ProgressCircle from "../components/ProgressCircle";
import Pie from "../components/Pie";
import HelloWorld from "../components/HelloWorld";
import Calendar from "../components/calendar"; 

import background from './Background.png';

Chart.register(...registerables);

function UserDashboard() {
  const { Logout, currentUser } = useAuth();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadError, setUploadError] = useState('');
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');    
  const [filter, setFilter] = useState('all'); // Assuming 'all' is the default filter
  const [showDeleteButtons, setShowDeleteButtons] = useState(false);
  const [chatPrompt, setChatPrompt] = useState('');
  const [chatResponse, setChatResponse] = useState('');
  const db = getFirestore();
  const [chatHistory, setChatHistory] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();

  const onUploadClick = (event) => {
  event.preventDefault(); // Prevent the default form submit action
  setShowUploadModal(true);
};

  const handleButtonClick = () => {
        console.log("Button clicked, attempting to navigate");
        navigate('/calendar'); 
    };

    //code for tax graph
const taxData = [
  { year: '2020', amount: 5000 },
  { year: '2021', amount: 4700 },
  { year: '2022', amount: 5300 },
  // add more years and amounts as needed
];

  //env tracker
  // Example user data (replace with actual data retrieval logic)
  const userData = {
    documentsCount: 50 // Example value
  };
  
  //transcript display

  const [selectedTranscriptFile, setSelectedTranscriptFile] = useState(null);

const showTranscript = (fileName) => {
  setUploadedFiles(currentFiles => currentFiles.map(file => {
    if (file.name === fileName) {
      // Extract just the file name without path for comparison
      const extractedText = extractedTexts.find(text => {
        const extractedFileName = text.file.split('/').pop(); // Adjust this line based on your file path structure
        return extractedFileName === fileName;
      });
      return {
        ...file,
        transcript: extractedText ? extractedText.text : "No transcript available.",
        showTranscript: !file.showTranscript
      };
    }
    return file;
  }));
};

  // New state for extracted texts
  const [extractedTexts, setExtractedTexts] = useState([]);
  const [showExtractedTexts, setShowExtractedTexts] = useState(false);

  // Function to fetch extracted texts
const fetchExtractedTexts = useCallback(async () => {
  const textsRef = collection(db, "extractedText");
  const querySnapshot = await getDocs(textsRef);
  const texts = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  console.log(texts); // Log to see if texts are being fetched!
  setExtractedTexts(texts);
}, [db]);

  useEffect(() => {
    fetchExtractedTexts();
  }, [fetchExtractedTexts]);

  // Function to render the extracted text display
const ExtractedTextDisplay = ({ texts, onClose }) => {
  return (
    <div className="section-container extracted-texts-section">
      <div className="uploaded-files-header">
        <h3 className="uploaded-files-title">Extracted Texts</h3>
        <button onClick={onClose}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="files-in-category">
        {texts.map((text, index) => (
          <div key={index} className="file-item">
            <p>{text.text || "No text extracted."}</p>
          </div>
        ))}
        {texts.length === 0 && <p>No texts extracted.</p>}
      </div>
    </div>
  );
};
  
    // New state for category and camera capture
  const [selectedCategory, setSelectedCategory] = useState('Bills');
  const [cameraCapture, setCameraCapture] = useState(null);
  
    //categories
  const categories = ["Bills", "Expense Receipt", "Invoice", "Client File", /* other categories */ ];


    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Add toggleSidebar function
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleCameraCaptureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCameraCapture(file);
      setSelectedFile(file); // Set this file as the selected file for upload
    }
  };
  
  //const newMessageRef = collection(db, 'users', currentUser.uid, 'messages');

const fetchUploadedFiles = useCallback(async () => {
  if (currentUser && currentUser.uid) {
    const storage = getStorage();
    const userStorageRef = ref(storage, `uploads/${currentUser.uid}`);
    try {
      const filesList = await listAll(userStorageRef);
      const fileInfos = await Promise.all(filesList.items.map(async (fileRef) => {
        const url = await getDownloadURL(fileRef);
        const metadata = await getMetadata(fileRef);
    return {
      url: url,
      name: fileRef.name,
      category: metadata.customMetadata?.category || 'Unknown',
      showTranscript: false, // Add this line
      transcript: '' // Add this line
    };
  }));
  setUploadedFiles(fileInfos);
    } catch (error) {
      console.error('Error fetching uploaded files', error);
    }
  }
}, [currentUser]);

useEffect(() => {
  console.log("Current User:", currentUser);

  // Ensuring currentUser exists and has uid
  if (currentUser && currentUser.uid) {
  //  console.log("Current User UID:", currentUser.uid);
    fetchUploadedFiles();
  } else {
    console.log("No user logged in or currentUser is not set properly.");
  }
}, [currentUser, fetchUploadedFiles]);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    }
  };

const startEditing = (fileName) => {
  setUploadedFiles(currentFiles => {
    const updatedFiles = currentFiles.map(file => file.name === fileName ? { ...file, isEditing: true } : file);
    console.log("Updated Files in startEditing:", updatedFiles);
    return updatedFiles;
  });
};

const handleNameChange = (event, fileName) => {
  setUploadedFiles(currentFiles =>
    currentFiles.map(file => file.name === fileName ? { ...file, newName: event.target.value } : file)
  );
};

const saveNewName = async (file) => {
  try {
    const storage = getStorage();
    const oldRef = ref(storage, `uploads/${currentUser.uid}/${file.name}`);
    const newRef = ref(storage, `uploads/${currentUser.uid}/${file.newName}`);

    // Step 1: Download the file
    const url = await getDownloadURL(oldRef);
    const response = await fetch(url);
    const blob = await response.blob();

    // Step 2: Upload the file with the new name
    await uploadBytes(newRef, blob);

    // Step 3: Delete the old file
    await deleteObject(oldRef);

    // Update local state
    setUploadedFiles(currentFiles =>
      currentFiles.map(f => f.name === file.name ? { ...f, name: file.newName, isEditing: false } : f)
    );
  } catch (error) {
    console.error('Error saving the new file name', error);
    // Optionally, update state to show an error message
  }
};

    // Function to handle changes to the filter dropdown
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  

  // Add a function to handle search input changes
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Add a function to filter uploaded files based on the search term and filter
  const filteredFiles = uploadedFiles.filter(file => {
    return file.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (filter === 'all' || file.type === filter); // Assume 'type' is a property of file
  });





  
  
const performFileUpload = () => {
  if (!currentUser) {
    setUploadError("You must be logged in to upload files.");
    return;
  }
  if (!selectedFile) {
    setUploadError("No file selected!");
    return;
  }

  setUploadError('');
  const storage = getStorage();
  const storageRef = ref(storage, `uploads/${currentUser.uid}/${selectedFile.name}`);

  // Include category as metadata
  const metadata = {
    contentType: selectedFile.type,
    customMetadata: {
      'category': selectedCategory
    }
  };

  const uploadTask = uploadBytesResumable(storageRef, selectedFile, metadata);

  uploadTask.on('state_changed',
    (snapshot) => {
      const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(prog);
    },
    (error) => {
      setUploadError(error.message);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        console.log('File available at', downloadURL);
        setShowUploadModal(false);
        setProgress(0);
        setSelectedFile(null);
        fetchUploadedFiles();
      });
    }
  );
};



  

const groupFilesByCategory = () => {
  const filteredFiles = uploadedFiles.filter(file => {
    const matchesSearchTerm = file.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filter === 'all' || file.category === filter;
    return matchesSearchTerm && matchesFilter;
  });

  const grouped = {};
  filteredFiles.forEach(file => {
    const category = file.category || 'Other'; // Fallback to 'Other' if no category
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push(file);
  });
  return grouped;
};

const renderUploadedFilesByCategory = () => {
  const groupedFiles = groupFilesByCategory();
  return Object.entries(groupedFiles).length > 0 ? (
    Object.entries(groupedFiles).map(([category, files]) => (
      <div key={category} className="category-section">
        <h3 className="category-title">{category}</h3>
        <div className="files-in-category">
          {files.map((file, index) => (
            <div key={index}>
              <div className="file-item">
                <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                {showDeleteButtons && (
                  <>
                    <button onClick={() => startEditing(file.name)}>Edit</button>
                    <button onClick={() => confirmDelete(file.name)}>Delete</button>
                    <button onClick={() => showTranscript(file.name)}>Transcript</button>
                  </>
                )}
              </div>
              {file.showTranscript && (
                <div className="file-transcript">
                  <p>{file.transcript}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    ))
  ) : (
    <p>No files in category: {filter}</p>
  );
};

const renderUploadedFiles = () => {
  // Filter files based on search term and filter selection
  const filteredFiles = uploadedFiles.filter(file => {
    const matchesSearchTerm = file.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter = filter === 'all' || file.type === filter; // assuming 'type' is a property of file
    return matchesSearchTerm && matchesFilter;
  });

  // Return JSX for rendering the filtered list of files
  return filteredFiles.length > 0 ? (
    filteredFiles.map((file, index) => (
      <div key={index}>
        <div className="file-item-container">
          <div className="file-item">
            {file.isEditing ? (
              <>
                <input
                  type="text"
                  value={file.newName}
                  onChange={(e) => handleNameChange(e, file.name)}
                />
                <button onClick={() => saveNewName(file)}>Save</button>
              </>
            ) : (
              <>
                <a href={file.url} target="_blank" rel="noopener noreferrer">{file.name}</a>
                {showDeleteButtons && (
                  <>
                    <button onClick={() => startEditing(file.name)}>Edit</button>
                    <button onClick={() => confirmDelete(file.name)}>Delete</button>
                    <button onClick={() => showTranscript(file.name)}>Transcript</button>
                  </>
                )}
              </>
            )}
          </div>
          {file.showTranscript && (
            <div className="file-transcript">
              <p>{file.transcript}</p>
            </div>
          )}
        </div>
      </div>
    ))
  ) : (
    <p>No files found.</p>
  );
};



  

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };




  
//file deltion code  
const confirmDelete = (fileName) => {
  if (window.confirm(`Are you sure you want to delete ${fileName}?`)) {
    deleteFile(fileName);
  }
};

const deleteFile = async (fileName) => {
  try {
    const storage = getStorage();
    const fileRef = ref(storage, `uploads/${currentUser.uid}/${fileName}`);

    // Delete the file
    await deleteObject(fileRef);

    // Update state to remove the file from the list
    setUploadedFiles(uploadedFiles.filter(file => file.name !== fileName));
  } catch (error) {
    console.error('Error deleting the file', error);
    // Optionally, update state to show an error message
  }
};
  
return (
<div className="dashboard">

    {/* Main Dashboard Content */}
    <div className="content" style={{ maxWidth: '90%', margin: '0 auto' }}>
          <div>
    <Header />
      </div>
      {/* Row 1 */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div className="HoldingCell">
          <AlertsAndNotifications />
        </div>
       <div className="HoldingCell">
          <Geography />
        </div>
        <div className="HoldingCell">
          <TaxBillTracker taxData={taxData} />
        </div>
      </div>

              {/* Row upload */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
       <div className="HoldingCell">
          <Line />
        </div>
       <div className="HoldingCell">
          <Pie />
        </div>
     <div className="HoldingCell">
<EnvironmentalTracker userData={userData} />
        </div>
      </div>

      {/* Row 2 */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
       <div className="HoldingCell">
          <Pie />
        </div>
        <div className="HoldingCell">
          <ChatInterface />
        </div>
       <div className="HoldingCell">
                        {/* Search and User Storage Info */}
        <div className="content-header">
          <input
            type="text"
            placeholder="Search files..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-bar"
          />
          <select value={filter} onChange={handleFilterChange} className="filter-dropdown">
    <option value="all">All</option>
    <option value="Bills">Bills</option>
    <option value="Expense Receipt">Expense Receipt</option>
    <option value="Invoice">Invoice</option>
    <option value="Client File">Client File</option>
            {/* Add more filter options as needed */}
          </select>
        </div>

      {/* Conditionally render the extracted texts or the uploaded files */}
      {showExtractedTexts ? (
        <ExtractedTextDisplay
          texts={extractedTexts}
          onClose={() => setShowExtractedTexts(false)}
        />
      ) : (
        renderUploadedFilesByCategory()
      )}
    </div>
          
  </div>

  {selectedTranscriptFile && (
    <div className="extracted-text-display">
      <h3>Transcript for {selectedTranscriptFile.name}</h3>
      <p>{selectedTranscriptFile.text}</p>
      <button onClick={() => setSelectedTranscriptFile(null)}>Close</button>
    </div>
  )}

        {/* Row 4 */}
{/*      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <div style={{ flex: '1', marginRight: '10px', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#FFFFFF' }}>
          <LineChart />
        </div>
        <div style={{ flex: '1', margin: '0 10px', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#FFFFFF' }}>
          <PieChart />
        </div>
        <div style={{ flex: '1', marginLeft: '10px', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', backgroundColor: '#FFFFFF' }}>
          <LineChart />
        </div>
      </div>
      
 ... more rows as needed ... */}
  

      {/* Hidden file input for triggering the file selection */}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      {/* Upload Modal */}
      {showUploadModal && (
        <UploadModal
          showUploadModal={showUploadModal}
          setShowUploadModal={setShowUploadModal}
        />
      )}
    </div>
  </div>
);
}

export default UserDashboard;
