import React from 'react';
import 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function TaxBillTracker({ taxData }) {
  // Generate chart data using the taxData prop
  const chartData = {
    labels: taxData.map(data => data.year),
    datasets: [
      {
        label: 'Tax Bill Amount',
        data: taxData.map(data => data.amount),
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    maintainAspectRatio: true, // Changed to true to maintain aspect ratio
    responsive: true, // If you want the chart to be responsive to window size
  };

  // Inline CSS styles
  const styles = {
    tracker: {
      padding: '20px',
      margin: '10px',
      height: '400px', // Set a fixed height
    },
  };

  return (
    <div style={styles.tracker}>
      <h2>Previous Tax Bills</h2>
      {/* Set a fixed height for the chart container */}
      <div style={{ height: '300px' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
}

export default TaxBillTracker;
