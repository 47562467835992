import React, { useEffect, useState } from "react";
import { FaApple, FaEye, FaEyeSlash, FaGoogle } from "react-icons/fa";
import { Link } from "react-router-dom";
import LoginImage from "../assets/desktop.png";
import { useAuth } from "../context/AuthContext";
import { appleProvider, googleProvider } from "../firebase";

// Import the background image
import Loginimg from '../assets/Loginimg.png';

function LoginPage() {
  const { Login, handleSocialLogin } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [value, setValue] = useState("");
  const handleGoogleLogin = () => {
    handleSocialLogin(googleProvider);
  };

  const handleAppleLogin = () => {
    handleSocialLogin(appleProvider);
  };

  useEffect(() => {
    setValue(localStorage.getItem("email"));
  });

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await Login(email, password);
      console.log(res);
      // Redirect user after successful login
    } catch (error) {
      console.error("Login error:", error.message);
      // Handle login error here
    }
  };
  return (
<div style={{ 
   backgroundImage: `url(${Loginimg})`,
   backgroundSize: 'cover',
   backgroundPosition: 'center',
   minHeight: '100vh',
}}>
    <section className="min-h-screen flex items-center justify-center">
        <div className="bg-gray-100 flex rounded-2xl shadow-lg max-w-3xl p-5 items-center">
          <div className="md:w-1/2 px-8 md:px-16">
            <h2 className="font-bold text-2xl text-[#002D74]">
              Login to MoneyBooks
            </h2>
            <p className="text-xs mt-4 text-[#002D74]">
              Already have an account, log in
            </p>
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <input
                className="p-2 mt-8 rounded-xl border"
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="relative">
                <input
                  className="p-2 rounded-xl border w-full"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span onClick={handlePassword}>
                  <span onClick={handlePassword}>
                    {showPassword ? (
                      <FaEyeSlash className="text-gray-500 absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer" />
                    ) : (
                      <FaEye className="text-gray-500 absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer" />
                    )}
                  </span>
                </span>
              </div>
              <button
                type="submit"
                className="bg-[#002D74] rounded-xl text-white py-2 hover:scale-105 duration-300"
              >
                Login
              </button>
            </form>
            <div className="mt-6 grid grid-cols-3 items-center text-gray-400">
              <hr className="border-gray-400" />
              <p className="text-center text-sm">OR</p>
              <hr className="border-gray-400" />
            </div>
            <button
              onClick={handleGoogleLogin}
              className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]"
            >
              <FaGoogle className="mr-3" /> Login with Google
            </button>
            <button
              onClick={handleAppleLogin}
              className="bg-white border py-2 w-full rounded-xl mt-5 flex justify-center items-center text-sm hover:scale-105 duration-300 text-[#002D74]"
            >
              <FaApple className="mr-3" /> Login with Apple
            </button>
            <div className="mt-5 text-xs border-b border-[#002D74] py-4 text-[#002D74]">
              <Link to="/forgot-password">Forgot your password?</Link>
            </div>
            <div className="mt-3 text-xs flex justify-between items-center text-[#002D74]">
              <p>Don't have an account?</p>
              <Link
                to="/registration"
                className="py-2 px-5 bg-white border rounded-xl hover:scale-110 duration-300"
              >
                Register
              </Link>
            </div>
          </div>
          {/* image */}
          <div className="md:block hidden w-1/2">
            <img className="rounded-2xl" src={LoginImage} alt="Login" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoginPage;
