import React, { useState, useEffect } from 'react';

function AlertsAndNotifications({ isAdmin }) {
  const [alerts, setAlerts] = useState([]);
  const [newAlertText, setNewAlertText] = useState('');

  useEffect(() => {
    // Replace this with your actual logic to fetch alerts
    const fetchedAlerts = getMockAlerts();
    setAlerts(fetchedAlerts);
  }, []);

  const handleDismiss = (alertId) => {
    setAlerts(alerts.filter(alert => alert.id !== alertId));
  };

  const handleAddAlert = () => {
    if (newAlertText.trim() !== '') {
      const newAlert = { id: Date.now(), message: newAlertText };
      setAlerts([...alerts, newAlert]);
      setNewAlertText('');
    }
  };

  // Inline CSS styles
  const styles = {
    alertItem: {
      marginBottom: '10px',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      backgroundColor: '#fff',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    alertMessage: {
      fontWeight: 'bold',
    },
    dismissButton: {
      cursor: 'pointer',
      color: '#888',
      border: 'none',
      background: 'none'
    },
    addAlertForm: {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      marginTop: '20px',
    },
    addAlertInput: {
      flex: 1,
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    addButton: {
      padding: '10px 20px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: '#4CAF50',
      color: 'white',
      cursor: 'pointer',
    }
  };

  return (
    <div style={styles.container}>
      <h2>Alerts and Notifications</h2>
      {alerts.length === 0 ? (
        <div>No alerts at this time.</div>
      ) : (
        alerts.map(alert => (
          <div key={alert.id} style={styles.alertItem}>
            <span style={styles.alertMessage}>{alert.message}</span>
            <button style={styles.dismissButton} onClick={() => handleDismiss(alert.id)}>Dismiss</button>
          </div>
        ))
      )}

      {isAdmin && (
        <div style={styles.addAlertForm}>
          <input
            type="text"
            style={styles.addAlertInput}
            placeholder="Add new alert..."
            value={newAlertText}
            onChange={(e) => setNewAlertText(e.target.value)}
          />
          <button style={styles.addButton} onClick={handleAddAlert}>Add</button>
        </div>
      )}
    </div>
  );
}

export default AlertsAndNotifications;

// Mock function to simulate fetching alerts
function getMockAlerts() {
  return [
    { id: 1, message: 'Reminder: Quarterly taxes due soon!' },
    { id: 2, message: 'New invoice received from supplier.' },
    // ... more alerts
  ];
}
